import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../general/Button';
import { HeadingTag, ResponsiveImage } from '..';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const FullBleedCard = ({ data, className, moduleName, moduleHeadingTitle, ...props }) => {
  const { logo, primaryCta, headingTitle } = data || {};

  const { t } = useTranslation('common');

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div
      {...props}
      className={classNames(
        'main-wrapper relative text-white flex flex-col xxl:flex-row font-cera w-full h-full',
        className,
      )}
    >
      <p className="mt-6 mx-auto  lg:mt-8 text-paragraph-normal not-italic  leading-24">{moduleHeadingTitle}</p>
      <div className="absolute w-full h-full content-wrapper flex flex-col grow  justify-center items-center">
        {logo && moduleName !== 'HotelCard' && (
          <div className="logo-wrapper flex flex-col gap-4 xxl:gap-6 h-full max-h-[190px] w-full max-w-[240px] lg:max-w-[311px] mb-[29px] xl:mb-[34px]">
            <ResponsiveImage
              image={logo}
              widths={{ xs: 240, sm: 240, md: 511, lg: 511, xl: 511, xxl: 511 }}
              imgProps={{ className: 'w-full h-full object-contain pointer-events-none' }}
            />
          </div>
        )}

        {moduleName === 'HotelCard' && (
          <div className="text-wrapper">
            <HeadingTag
              data={{ heading: headingTitle?.replace('Harbour Hotel', ''), htag: 'p' }}
              className={`
                font-ivy-ora text-center text-[56px] md:text-title-l not-italic font-normal leading-60 -tracking-normal text-secondary-coolsand 
                xl:text-title-xxl xl:leading-120 xl:-tracking-headingLight mb-[29px] xl:mb-[34px]
            `}
            />
          </div>
        )}
        {loaded && primaryCta && (
          <div className="button-wrapper inline-flex justify-between ">
            <Button
              theme={'light'}
              link={{
                ...primaryCta,
                text: moduleName === 'HotelCard' ? t('carousel.$viewHotel') : t('carousel.$viewRestaurant'),
              }}
              type={'primary'}
            ></Button>
          </div>
        )}
      </div>
    </div>
  );
};

FullBleedCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FullBleedCard;
