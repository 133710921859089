import React from 'react';
import classNames from 'classnames';

const RestaurantLogoCarouselPagination = ({ current, total, className, ...props }) => {
  const calculateLeftPosition = () => {
    return `${(current * 100) / total}%`;
  };

  const calculateWidth = () => {
    return `${100 / total}%`;
  };

  return (
    <div
      className={classNames('flex items-center w-fit carousel-pagination', className)}
      role="progressbar"
      aria-label="Progress"
      {...props}
    >
      <div
        className={classNames(``, 'w-[366px] parent-container h-0.5 relative bg-secondary-warmsand overflow-hidden')}
      >
        <div
          className={classNames(
            `${calculateWidth()} bg-primary opacity-90`,
            ' h-full active-child transition-all duration-700 absolute',
          )}
          style={{ left: calculateLeftPosition(), width: calculateWidth() }}
        />
      </div>
    </div>
  );
};

export default RestaurantLogoCarouselPagination;
