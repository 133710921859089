import { useState } from 'react';
import Pause from '@/assets/pause.svg';
import Play from '@/assets/play.svg';
import classNames from 'classnames';

const VideoPlayPauseControl = ({ media, autoPlay, className, ...props }) => {
  const [isPlaying, setisPlaying] = useState(autoPlay);

  const handlePlay = () => {
    if (isPlaying) {
      media?.current?.pause();
      setisPlaying(false);
    } else {
      media?.current?.play();
      setisPlaying(true);
    }
  };

  return (
    <div
      className={classNames(
        'absolute size-12 left-5 cursor-pointer bottom-6 backdrop-blur-[2px] rounded bg-primary bg-opacity-[0.24] lg:bottom-[130px]  lg:left-1/2 lg:-translate-x-1/2 hover:bg-opacity-100',
        className,
      )}
      onClick={() => {
        handlePlay();
      }}
      {...props}
    >
      <div className=" w-full h-full relative flex justify-center items-center fill-white">
        {isPlaying ? <Pause /> : <Play />}
      </div>
    </div>
  );
};

export default VideoPlayPauseControl;
