import { ModuleBase, Media, HeadingTag } from '@/components';
import FadeUpAnimation from '@/components/animations/FadeUpAnimation/FadeUpAnimation';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';
import { useEffect, useState } from 'react';

const HeroLeftAlignedModule = ({ data }) => {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <ModuleBase data={data} className="relative min-h-[404px] overflow-hidden">
      {loaded && data.backgroundMedia && (
        <div className="absolute inset-0 h-full w-full">
          <Media
            media={data.backgroundMedia}
            widths={{ xs: window.innerWidth }}
            heights={{ xs: 404 }}
            cover
            dataNotLazy
            showImageCredit
          />
        </div>
      )}
      <div className="absolute  z-[2]  h-full w-full gradient-light" />
      <div className="absolute z-[3] h-full w-full  text-white">
        <FadeUpStarter className="container pr-4 text-left lg:max-w-[1440px] md:left-1/2 md:-translate-x-1/2 absolute h-full flex items-end justify-start">
          {data.headingTitle && (
            <FadeUpAnimation>
              <HeadingTag
                data={data.headingTitle}
                className="font-ivy-ora text-left md:w-[430px] lg:w-[830px]  mb-4 md:mb-5 text-balance  leading-60 font-normal not-italic text-56  -tracking-normal lg:text-80 lg:leading-[85px] lg:tracking-[-3.2px]"
              />
            </FadeUpAnimation>
          )}
        </FadeUpStarter>
      </div>
    </ModuleBase>
  );
};

export default HeroLeftAlignedModule;
