import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ModuleBase, ResponsiveImage } from '@/components';
import classNames from 'classnames';
import { isColorDark } from '@/utils';
import { motion } from 'framer-motion';
import FadeUpStarter from '@/components/animations/FadeUpAnimation/FadeUpStarter';
import { transition } from '@/components/animations/FadeUpAnimation/transition';
import CardListItemsCarousel from './CardListItemsCarousel';

const CardListIemsModule = ({ data }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const bgDark = isColorDark(data.backgroundColour);
  const _setActiveCard = (card, index) => {
    setActiveCard(card);
    setActiveIndex(index);
  };

  return (
    <ModuleBase data={data}>
      <div className="hidden lg:flex px-0 relative">
        {data?.cards?.map((listItem) =>
          listItem.props?.cards.map((card, index) => (
            <div
              key={card.moduleId}
              className={classNames(
                'absolute w-full h-full top-0 left-0 duration-1000',
                activeCard?.moduleId === card.moduleId || (activeCard === null && index === 0)
                  ? 'opacity-100'
                  : 'opacity-0',
              )}
            >
              <ResponsiveImage
                image={card.props.image}
                widths={{ xs: 784, sm: 784, md: 784, lg: 1440 }}
                heights={{ xs: 840, sm: 840, md: 840, lg: 1260 }}
                showImageCredit
              />
            </div>
          )),
        )}
        <div className="absolute w-full h-full top-0 left-0 bg-black/30" />

        <FadeUpStarter className="flex flex-col lg:flex-row relative" onMouseLeave={() => _setActiveCard(null, null)}>
          {data?.cards?.map((listItem) =>
            listItem.props?.cards.map((card, index) => {
              const isActive = activeCard?.moduleId === card.moduleId;
              return (
                <motion.div
                  variants={{ inactive: {}, active: { transition, transitionEnd: { transitionDuration: '1s' } } }}
                  key={card.moduleId}
                  className={`w-full lg:w-1/3 flex flex-col items-center justify-between border-b lg:border-b-0 last:border-b-0 lg:border-r lg:last:border-r-0 border-secondary-warmsand/50 relative
                  px-4 ${isActive ? 'py-6' : 'py-8'} lg:p-4 lg:pb-6 lg:pt-[62px] lg:px-[57px] lg:pb-[113px] lg:h-[clamp(446px,95vh,840px)]`}
                  style={{
                    backgroundColor: isActive ? data.backgroundColour || 'white' : null,
                    borderRightColor: isActive ? data.backgroundColour || 'white' : null,
                  }}
                  onMouseOver={() => _setActiveCard(card, index)}
                  onClick={() => _setActiveCard(isActive ? null : card, index)}
                >
                  {card.props.image?.imageCredit && (
                    <div
                      className={classNames(
                        'image-credit transition-opacity duration-700',
                        activeIndex === 2 && index === 1 ? 'opacity-75' : 'opacity-0',
                      )}
                    >
                      {data?.cards[0]?.props?.cards[2]?.props?.image?.imageCredit}
                    </div>
                  )}

                  <div className="w-full">
                    <motion.p
                      variants={{
                        inactive: { opacity: 0, y: 20 },
                        active: { opacity: 1, y: 0, transition, transitionEnd: { transitionDuration: '1s' } },
                      }}
                      className={classNames(
                        'font-ivy-ora font-normal not-italic',
                        'text-headingReg leading-headingReg -tracking-wide',
                        'sm:text-t4 sm:leading-8 sm:-tracking-wider',
                        'lg:text-headingReg lg:leading-headingReg lg:-tracking-wide',
                        isActive ? (bgDark ? 'text-white' : 'text-primary') : 'text-white',
                      )}
                      role="heading"
                      aria-level="2"
                    >
                      {card.props.headingTitle}
                    </motion.p>
                    <div className="overflow-hidden">
                      <div
                        className={classNames(
                          'transition-all duration-1000',
                          isActive ? 'max-h-[400px] lg:max-h-fit lg:opacity-100' : 'max-h-0 lg:max-h-fit lg:opacity-0',
                        )}
                      >
                        <div
                          className={classNames(
                            'mt-6 md:mt-3.5 font-cera text-primary text-paragraph not-italic font-normal leading-6 duration-1000',
                            bgDark ? 'text-white' : 'text-primary',
                          )}
                          dangerouslySetInnerHTML={{ __html: card.props.description }}
                        />
                        <div>
                          <Button
                            type="tertiary"
                            theme={bgDark ? 'light' : 'dark'}
                            link={card.props.primaryCta}
                            className="w-fit mt-6"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full justify-between sm:justify-center items-center">
                    <motion.div
                      variants={{
                        inactive: { opacity: 0, y: 20 },
                        active: { opacity: 1, y: 0, transition, transitionEnd: { transitionDuration: '1s' } },
                      }}
                      className={classNames(
                        'mt-6 not-italic font-ivy-ora font-light',
                        'text-56 leading-60 -tracking-normal',
                        'lg:text-[120px] lg:leading-[120px] lg:-tracking-headingLight',
                        isActive ? (bgDark ? 'text-white' : 'text-primary') : 'text-white',
                      )}
                    >
                      {`${index + 1}`.padStart(2, '0')}
                    </motion.div>
                  </div>
                </motion.div>
              );
            }),
          )}
        </FadeUpStarter>
      </div>
      <div className="mobile-carousel-wrapper flex lg:hidden">
        <CardListItemsCarousel cards={data.cards} />
      </div>
    </ModuleBase>
  );
};

CardListIemsModule.propTypes = {
  data: PropTypes.any.isRequired,
  buttonWrapperClassName: PropTypes.string,
};

export default CardListIemsModule;
