import { Link, ResponsiveImage, FestiveButton } from '@/components';
import NavColumn from './NavColumn';
import NavArrow from '@/assets/navArrow.svg';
import classNames from 'classnames';
import tailwindConfig from '@/tailwind.config';
import { useEffect, useState } from 'react';

const NavItem = ({
  navItem,
  level,
  parentIndex,
  hasTwoColumn,
  activeMenu,
  setActiveMenu,
  unsetActiveMenu,
  mainNavTitle,
  close,
  parentMenuName,
  dividerTop,
  dividerBottom,
  isGlobalNavItem,
}) => {
  const _setActiveMenu = (sub) => {
    let newValue = {};
    newValue[`l${level + 1}`] = sub;
    setActiveMenu(newValue);
  };
  const _setActiveMenuByHover = (sub) => {
    if (window.innerWidth > parseInt(tailwindConfig.theme.screens.lg, 10)) {
      _setActiveMenu(sub);
    }
  };
  const _unsetActiveMenu = () => {
    if (window.innerWidth > parseInt(tailwindConfig.theme.screens.lg, 10)) {
      unsetActiveMenu();
    }
  };

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  const linkClass = classNames(
    'link-element text-sm leading-6 text-primary cursor-pointer flex items-center justify-between duration-500 [&_svg]:duration-500',
    'text-primary ',
    level === 2 ? 'hover:text-white [&_svg]:hover:fill-white' : 'hover:text-primary',
    activeMenu && activeMenu[`l${1}`] && navItem.flyout?.length > 0 ? 'active' : '',
    activeMenu && activeMenu[`l${level + 1}`] && navItem.flyout?.length > 0
      ? level === 2
        ? 'lg:text-brand-soft-white lg:[&_svg]:fill-brand-soft-white'
        : 'lg:text-primary'
      : level === 2
        ? 'lg:text-brand-soft-white lg:[&_svg]:fill-brand-soft-white'
        : 'lg:text-primary',
  );

  return (
    <>
      {dividerTop && <div className="bg-secondary-warmsand/10 h-[1px] my-2" />}
      <div>
        {navItem.flyout?.length > 0 ? (
          <div
            className={linkClass}
            onClick={() => _setActiveMenu({})}
            onMouseEnter={() => {
              _setActiveMenuByHover({});
            }}
          >
            {navItem.navItemText || navItem.navigationalTitle}
            {navItem.flyout?.length > 0 && (
              <NavArrow className={classNames(isGlobalNavItem ? '[&_path]:!stroke-white' : '')} role="presentation" />
            )}
          </div>
        ) : navItem?.linkElements?.length > 0 ? (
          <div className={`flex flex-col gap-4 ${navItem.linkElements.length === 1 ? 'break-inside-avoid' : ''}`}>
            <div className="text-primary font-medium text-sm leading-6">{navItem.navigationalTitle}</div>
            <div className="flex flex-col gap-4">
              {navItem.linkElements.map((link, index) => (
                <div key={index}>
                  <Link
                    className={`${linkClass} w-fit xl:[&:hover+.image-block]:opacity-100`}
                    link={link.link}
                    onClick={close}
                  >
                    {link.link?.text}
                  </Link>

                  {loaded && link.image && window.innerWidth > parseInt(tailwindConfig.theme.screens.xl, 10) && (
                    <div
                      className={`image-block absolute w-1/4 h-full top-0 right-0 transition-opacity duration-500  xxl:z-[-1] opacity-0
                      ${hasTwoColumn ? 'translate-x-[-100%]' : 'translate-x-[-200%]'}
                      `}
                      style={{ zIndex: (parentIndex + 1 + index + 1) * -1 }}
                    >
                      <ResponsiveImage
                        dataNotLazy
                        image={link.image}
                        widths={{ xs: window.innerWidth / 4 }}
                        heights={{ xs: window.innerHeight }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
            {navItem.ctaLink && (
              <Link className={`${linkClass} underline`} link={navItem.ctaLink} onClick={close}>
                {navItem.ctaLink.text}
              </Link>
            )}
          </div>
        ) : navItem.isFestiveLink ? (
          <FestiveButton link={navItem.navItemLink} onClick={close} className="w-fit">
            {navItem?.navItemText || navItem.navItemLink?.text || navItem.navigationalTitle || navItem.ctaLink?.text}
          </FestiveButton>
        ) : (
          <Link
            className={linkClass}
            link={
              navItem.navItemLink ||
              (navItem.ctaLink && {
                ...navItem.ctaLink,
                text: navItem.navigationalTitle || navItem.navItemText || navItem.ctaLink.text,
              })
            }
            onClick={close}
            onMouseEnter={() => _unsetActiveMenu()}
          >
            {navItem?.navItemText || navItem.navItemLink?.text || navItem.navigationalTitle || navItem.ctaLink?.text}
          </Link>
        )}

        {(!navItem.flyout || navItem.flyout.length === 0) &&
          !(navItem.navItemLink || navItem.ctaLink) &&
          navItem.linkElements?.length === 0 && (
            <div className={linkClass} onClick={() => _setActiveMenu({})}>
              {navItem.navItemText || navItem.navigationalTitle}
            </div>
          )}
      </div>
      {navItem.flyout?.length > 0 && (
        <NavColumn
          navItems={navItem.flyout}
          mainNavTitle={mainNavTitle}
          flyoutBottom={navItem.flyoutBottom}
          flyouHead={navItem.navItemLink}
          allHotels={navItem.allHotels}
          detailedNavItems={navItem.detailedNavItems}
          level={level + 1}
          activeMenu={activeMenu}
          setActiveMenu={(sub) => _setActiveMenu(sub)}
          close={close}
          cta={navItem.flyout && navItem.flyout.length !== 0 ? navItem.navItemLink || navItem.ctaLink : null}
          ctaClass={linkClass}
          parentMenuName={level === 0 ? navItem.navItemText || navItem.navigationalTitle : parentMenuName}
        />
      )}
      {dividerBottom && <div className="bg-primary/10 h-[1px] mx-10" />}
    </>
  );
};

export default NavItem;
