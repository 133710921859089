import PropTypes from 'prop-types';
import { ResponsiveImage, PreHeading } from '..';
import Flicking, { FlickingError } from '@egjs/react-flicking';
import { useTranslation } from 'next-i18next';
import Button from '../general/Button';
import { useEffect, useRef, useState } from 'react';
import CarouselPagination from '../general/CarouselPagination';
import tailwindConfig from '../../tailwind.config.js';
import Arrow from '@/assets/arrowForward.svg';
import { Link } from '@/components';
import classNames from 'classnames';

const GridCard = ({ data, cardsPerRow, cardType }) => {
  const { preHeading, headingTitle, description, subtitles, images, image, primaryCta, isHighlighted } =
    data.props || {};
  const { t } = useTranslation('common');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [panelCount, setPanelCount] = useState(0);
  const isArticle = data.moduleName === 'ArticleCard';
  const carousel = useRef();

  const willChange = (e) => {
    setCurrentIndex(e.index);
  };

  const onReady = () => {
    setPanelCount(carousel.current.panelCount);
    flickingMove();
  };

  const menuHeight = 105;
  const extraPadding = 20;
  const cardBaseHeight = useRef(800);
  const cardBaseHeightXXL = useRef(837);
  const cardBaseHeightXXLTwoCard = useRef(774);
  const cardBaseHeightXXLTwoCardMd = useRef(817);
  const cardImageBaseHeight = useRef(435);
  const cardImageBaseHeightXXLTwoCard = useRef(498);
  const cardImageBaseHeightXXL = useRef(597);
  const cardBaseHeightOneCard = useRef(897);
  const cardBaseHeightOneCardMD = useRef(695);
  const cardImageBaseHeightOneCard = useRef(700);
  const needScale = useRef(false);

  if (typeof window !== 'undefined' && !window.navigator.userAgent.includes('Safari')) {
    if (
      (cardsPerRow === 3 && window.innerHeight < cardBaseHeight.current + menuHeight + extraPadding) ||
      (cardsPerRow === 2 && window.innerHeight < cardBaseHeightXXLTwoCard.current + menuHeight + extraPadding)
    ) {
      cardBaseHeight.current = 600;
      cardBaseHeightXXL.current = 637;
      cardBaseHeightXXLTwoCard.current = 574;
      cardBaseHeightXXLTwoCardMd.current = 617;

      cardImageBaseHeight.current = 235;
      cardImageBaseHeightXXLTwoCard.current = 298;
      cardImageBaseHeightXXL.current = 397;

      cardBaseHeightOneCard.current = 697;
      cardBaseHeightOneCardMD.current = 495;
      cardImageBaseHeightOneCard.current = 500;
      needScale.current = true;
    }
  }

  let progressPadding = 0;

  const calcStyleValue = (progressMax, progress, valueStart, valueEnd) => {
    const decreasingProgress = progressMax - progress;
    const progressTime = valueEnd - decreasingProgress - (valueEnd - progressMax) / (progressMax / decreasingProgress);
    const value = decreasingProgress * (valueStart / progressMax) + progressTime;
    return Math.max(value, valueEnd);
  };

  const flickingMove = () => {
    const panels = carousel.current.visiblePanels;
    panels.forEach((panel) => {
      const progress = Math.abs(panel.outsetProgress);
      if (progress !== 0 && progressPadding === 0) {
        const windowSize = window.innerWidth;
        if (windowSize < parseInt(tailwindConfig.theme.screens.md, 10)) {
          progressPadding = 0.85; // sm
        } else if (windowSize < parseInt(tailwindConfig.theme.screens.xl, 10)) {
          progressPadding = 0.8; // md
        } else {
          progressPadding = 0.65; // lg
        }
      }
      const target = panel.element;
      let imgOpacity;
      if (progress !== 0) {
        imgOpacity = calcStyleValue(progressPadding, progress, 1, 0.9);
      }
      target.style.opacity = imgOpacity;
    });
  };

  const move = async (dir) => {
    try {
      if (dir > 0) {
        await carousel.current.next();
      } else {
        await carousel.current.prev();
      }
    } catch (err) {
      if (!(err instanceof FlickingError)) {
        throw new Error(err);
      }
    }
  };

  const xxlImageSizeWidth = (cardsPerRow) => {
    switch (cardsPerRow) {
      case 2:
      case 3:
        return isArticle
          ? { xs: 306, md: 306, lg: 459, xxl: cardsPerRow === 1 ? 1409 : cardsPerRow === 2 ? 696 : 459 }
          : { xs: 343, md: 343, xxl: 658 };
      default:
        return { xs: 343, md: 658, xxl: 1356 };
    }
  };

  const xxlImageSizeHeight = (cardsPerRow) => {
    switch (cardsPerRow) {
      case 2:
      case 3:
        return isArticle
          ? { xs: 306, md: 306, lg: 425 }
          : { xs: cardImageBaseHeight.current, md: cardImageBaseHeight.current, xxl: 658 };
      default:
        return { xs: 435, md: 435, xxl: 700 };
    }
  };

  const cardsPerRowClass = (cardsPerRow) => {
    if (isHighlighted || cardsPerRow === 1) return '';
    switch (cardsPerRow) {
      case 1:
        return '';
      case 2:
        return 'two-col';
      case 3:
        return 'three-col';
      default:
        return '';
    }
  };

  const contentHeight =
    cardsPerRow === 3
      ? 'md:h-[308px] lg:h-[260px] lg:min-h-[260px] xxl:min-h-[308px] xxl:max-h-[308px]'
      : 'md:h-[308px] lg:h-[260px] lg:min-h-[260px] xxl:min-h-[276px] xxl:max-h-[276px]';
  let cardWidth =
    cardsPerRow === 2
      ? `w-[323px] xs:w-[343px] md:w-[323px] lg:w-[460px] xl:w-[580px] xxl:w-[658px]`
      : 'w-[323px] xs:w-[343px] lg:w-[460px] xl:w-[372px] xxl:w-[428px]';
  let cardHeight =
    cardsPerRow === 2
      ? `h-fit xxl:h-[${cardBaseHeightXXLTwoCard.current}px]`
      : `xxl:min-h-[${cardBaseHeightXXL.current}px]`;
  let imageWidthContainer =
    cardsPerRow === 2
      ? 'w-[323px] xs:w-[343px] lg:w-[460px] xl:w-[580px] xxl:w-[658px]'
      : 'w-[323px] xs:w-[343px] lg:w-[460px] xl:w-[372px] xxl:w-[428px]';
  let imageHeightContainer =
    cardsPerRow === 2
      ? `h-[${cardImageBaseHeight.current}px] xxl:h-[${cardImageBaseHeightXXLTwoCard.current}px]`
      : ` h-[${cardImageBaseHeight.current}px] xxl:h-[${cardImageBaseHeightXXL.current}px]`;
  const imageWidth =
    cardsPerRow === 2
      ? 'w-[293px] md:w-[270px] lg:w-[386px] xl:w-[490px] xxl:w-[532px]'
      : 'w-[293px] md:w-[270px] lg:w-[298px] xxl:w-[368px]';
  const imageHeight =
    cardsPerRow === 2
      ? `h-[${cardImageBaseHeight.current}px] xxl:h-[${cardImageBaseHeightXXLTwoCard.current}px]`
      : ` h-[${cardImageBaseHeight.current}px] xxl:h-[${cardImageBaseHeightXXL.current}px]`;
  const isHighlightedColSpan =
    cardsPerRow === 2
      ? 'md:col-span-2'
      : cardsPerRow === 3
        ? 'md:col-span-2 xl:col-span-3'
        : 'md:col-span-2 xxl:col-span-1';
  const isHighlightedClass =
    (isHighlighted || cardsPerRow === 1) && !isArticle
      ? `${isHighlightedColSpan} md:h-[${cardBaseHeightOneCardMD.current}px] md:!w-[724px] lg:!w-[960px] xl:!w-[1200px] ${cardsPerRow === 3 ? 'xl:!w-[1110px]' : ''} xxl:h-[${cardBaseHeightOneCard.current}px] xxl:!w-full`
      : '';
  const isHighlightedImageContainer =
    (isHighlighted || cardsPerRow === 1) && !isArticle
      ? ` md:!w-[724px] lg:!w-[960px] xl:!w-[1200px] ${cardsPerRow === 3 ? 'xl:!w-[1110px] xl:!h-[300px]' : ''} xxl:!w-full xxl:!h-[${cardImageBaseHeightOneCard.current}px]`
      : '';
  const isHighlightedImage =
    (isHighlighted || cardsPerRow === 1) && !isArticle
      ? ` !w-[293px] md:!w-[644px] ${cardsPerRow === 3 ? 'xl:!w-[990px] xl:!h-[300px]' : 'lg:!w-[842px] xl:!w-[1080px]'} xxl:!w-[1169px] xxl:!h-[${cardImageBaseHeightOneCard.current}px]`
      : '';
  const iWidths =
    (isHighlighted || cardsPerRow === 1) && !isArticle
      ? { xs: 343, md: 695, xxl: 1356 }
      : xxlImageSizeWidth(cardsPerRow);
  const iHeights =
    (isHighlighted || cardsPerRow === 1) && !isArticle
      ? { xs: 435, md: 435, xxl: 700 }
      : xxlImageSizeHeight(cardsPerRow);
  const isHighlightedContent =
    (isHighlighted || cardsPerRow === 1) && !isArticle
      ? 'md:!h-[260px] xxl:!h-[197px] xxl:!min-h-[197px] xxl:!py-4 xxl:!px-6 xxl:!gap-3'
      : '';
  const isHighlightedTextContent =
    (isHighlighted || cardsPerRow === 1) && !isArticle ? 'xxl:!grid grid-cols-3 xxl:!gap-10 xxl:h-[85%]' : '';
  const isHighlightedContentDesc =
    (isHighlighted || cardsPerRow === 1) && !isArticle ? ' xxl:!line-clamp-3  xxl:!text-[16px]' : '';
  const isHighlightedTopRow = (isHighlighted || cardsPerRow === 1) && !isArticle ? ' h-[45px]' : '';

  let articleContent = '';
  let articleTop = '';
  let articlePreHeading = '';
  let articleTextContent = '';
  let articleHeading = '';
  let articleDescription = '';

  if (isArticle) {
    cardWidth =
      cardsPerRow === 2 ? '!w-[306px] md:!w-[306px] lg:!w-[459px] xxl:!w-[696px]' : '!w-[306px] lg:!w-[459px]';
    cardHeight = cardsPerRow === 2 ? '!h-[612px] lg:!min-h-[810px] xxl:!h-[810px]' : '!min-h-[612px] lg:!min-h-[810px]';

    imageWidthContainer = cardsPerRow === 2 ? '!w-[306px] lg:!w-[459px] xxl:!w-[696px]' : '!w-[306px] lg:!w-[459px]';
    imageHeightContainer = cardsPerRow === 2 ? '!h-[306px] lg:!h-[425px] xxl:!h-[425px]' : '!h-[306px] lg:!h-[425px]';
    articleContent =
      '!min-h-[306px] !h-[306px]  lg:!h-[385px] lg:!min-h-[385px] !gap-4 lg:!gap-6 !p-4 lg:!p-10 justify-center lg:justify-end';

    articleTop = '!h-4';
    articlePreHeading = '!text-[10px] !leading-4 !text-primary';
    articleTextContent = '!gap-4 lg:!gap-6';
    articleHeading = '!text-[20px] !leading-[24px] lg:!text-[24px] lg:!leading-[26px]';
    articleDescription = '!text-[14px] lg:!text-[16px]';
    if (cardsPerRow === 1) {
      cardWidth += ' xxl:!w-full';
      imageWidthContainer += ' xxl:!w-full';
    }
  }

  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    loaded && (
      <div
        className={`${cardWidth} ${cardHeight} ${isHighlightedClass}  overflow-hidden m-auto xxl:m-0 text-primary 
        [&:hover_.cta-container]:opacity-100 [&:hover_.arrow-container]:lg:opacity-100 
      `}
      >
        <div
          className={`${imageWidthContainer} ${imageHeightContainer} ${isHighlightedImageContainer} ${images && images.length > 1 ? 'bg-white' : 'bg-slate-200'} relative`}
        >
          {isArticle && image && <ResponsiveImage image={image} widths={iWidths} heights={iHeights} showImageCredit />}
          {images && images.length == 1 && (
            <ResponsiveImage image={images[0]} widths={iWidths} heights={iHeights} showImageCredit />
          )}
          {images && images.length > 1 && (
            <div className="relative">
              <Flicking
                ref={carousel}
                align="prev"
                useResizeObserver={true}
                horizontal={true}
                circular={true}
                circularFallback="bound"
                className=""
                onWillChange={willChange}
                onReady={onReady}
                onMove={flickingMove}
              >
                {images.map((cardImage, cardImageIndex) => (
                  <div
                    className={`${imageWidth} ${imageHeight} ${isHighlightedImage} relative`}
                    key={data.moduleId + cardImageIndex}
                  >
                    <ResponsiveImage image={cardImage} widths={iWidths} heights={iHeights} showImageCredit />
                    <div
                      className={classNames(
                        'absolute left-0 top-0 w-full h-full bg-black/50 duration-300 transition-all',
                        currentIndex !== cardImageIndex
                          ? 'opacity-1 duration-700 transition-all'
                          : 'opacity-0 duration-700 transition-all',
                      )}
                    ></div>
                  </div>
                ))}
              </Flicking>

              <CarouselPagination
                current={currentIndex}
                total={panelCount}
                theme={'light'}
                className={`pagination mx-auto z-10 absolute right-4 md:right-2 xxl:right-4 bottom-2 ${cardsPerRowClass(cardsPerRow)}
                    [&_.currentPage]:text-secondary-coolsand [&_.totalPage]:text-secondary-coolsand
                    [&_.currentPage]:md:text-[40px] [&_.currentPage]:md:leading-[44px] [&_.currentPage]:md-tracking-wide
                    [&_.currentPage]:xxl:text-[80px] [&_.currentPage]:xxl:leading-[72px] [&_.currentPage]:xxl:-tracking-ultraWide
                    [&_.totalPage]:md:text-[40px] [&_.totalPage]:md:leading-[44px] [&_.totalPage]:md:-tracking-wide
                    [&_.totalPage]:xxl:text-[80px] [&_.totalPage]:xxl:leading-[72px] [&_.totalPage]:xxl:-tracking-ultraWide
                    xxl:bottom-6 xxl:right-6
                    [&.two-col_.currentPage]:xxl:text-[40px] [&.two-col_.currentPage]:xxl:leading-[44px] [&.two-col_.currentPage]:xxl:-tracking-wide
                    [&.two-col_.totalPage]:xxl:text-[40px] [&.two-col_.totalPage]:xxl:leading-[44px] [&.two-col_.totalPage]:xxl:-tracking-wide
                    [&.three-col_.currentPage]:xxl:text-[24px] [&.three-col_.currentPage]:xxl:leading-[32px] [&.three-col_.currentPage]:xxl:-tracking-wide
                    [&.three-col_.totalPage]:xxl:text-[24px] [&.three-col_.totalPage]:xxl:leading-[32px] [&.three-col_.totalPage]:xxl:-tracking-wide
                    [&.two-col]:md:right-7 [&.two-col]:xxl:right-4 [&.two-col]:xxl:bottom-2 
                    [&.three-col]:md:right-7 [&.three-col]:xxl:right-4 [&.three-col]:xxl:bottom-2 
                    ${needScale.current === true ? 'xl:scale-[0.8] xl:bottom-0 xxl:scale-[1] xxl:bottom-6' : ''}
                    `}
              />
              <div
                className={`arrow-container hidden ${cardsPerRowClass(cardsPerRow)}
                    md:absolute md:right-[75px] xxl:right-[146px] md:top-1/2 md:-translate-y-1/2 md:z-20 md:w-[88px] 
                    md:h-[88px] md:bg-secondary-warmsand/30 md:rounded-full
                    md:flex md:justify-between md:items-center
                    [&.two-col]:md:right-[30px] [&.two-col]:xl:right-[46px] [&.two-col]:xxl:right-[82px] 
                    [&.three-col]:md:right-[30px] [&.three-col]:xxl:right-[16px] 
                    opacity-0 traniiton-opacity duration-500
                    ${needScale.current === true ? 'xl:scale-[0.8] xl:mt-[-10px] xxl:scale-[1] xxl:mt-[0px]' : ''}
               `}
              >
                <button
                  className="p-0 w-[44px] h-[44px] sm:w-12 sm:h-12 z-10  flex justify-center items-center"
                  onClick={move}
                  aria-label="Prevues"
                >
                  <Arrow className="" />
                </button>
                <div className="divider rounded-full bg-white w-[2px] h-[2px]"></div>
                <button
                  className="p-0 w-[44px] h-[44px] sm:w-12 sm:h-12 z-10 rotate-180  flex justify-center items-center"
                  onClick={() => move(1)}
                  aria-label="Next"
                >
                  <Arrow />
                </button>
              </div>
            </div>
          )}
        </div>
        <div
          className={`py-6 px-4 xxl:p-6 bg-white flex flex-col gap-6 xxl:gap-3 h-fit ${contentHeight} ${isHighlightedContent} ${articleContent}`}
        >
          <div className={`h-7 flex justify-between items-center ${isHighlightedTopRow} ${articleTop}`}>
            {preHeading && (
              <PreHeading className={`text-[14px] text-primary/75 leading-4 ${articlePreHeading}`}>
                {preHeading}
              </PreHeading>
            )}
            {!isArticle && primaryCta && (
              <div
                className={`cta-container transition-opacity duration-500 pt-2 lg:opacity-0 button-wrapper inline-flex justify-between ${preHeading ? 'w-auto' : 'w-full'}`}
              >
                <Button
                  className="ml-auto"
                  theme={'dark'}
                  link={{
                    ...primaryCta,
                    text:
                      data?.props?.primaryCta?.text && cardType === 'Custom'
                        ? data?.props?.primaryCta?.text
                        : t('cards.$discoverMore'),
                  }}
                  type={'tertiary'}
                ></Button>
              </div>
            )}
          </div>
          <div className={`flex flex-col gap-6 lg:gap-2 xxl:gap-4 ${isHighlightedTextContent} ${articleTextContent}`}>
            {headingTitle &&
              (primaryCta ? (
                <Link link={primaryCta} className={`font-ivy-ora text-[40px] leading-[58px]  ${articleHeading}`}>
                  {headingTitle.length > 33 ? `${headingTitle.substring(0, 33)}...` : headingTitle}
                </Link>
              ) : (
                <p className={`font-ivy-ora text-[40px] leading-[58px] line-clamp-1 ${articleHeading}`}>
                  {headingTitle.length > 33 ? `${headingTitle.substring(0, 33)}...` : headingTitle}
                </p>
              ))}

            {subtitles && subtitles.length > 0 && (
              <div className="items-center gap-6 min-h-6 text-primary/75 flex">
                {subtitles.map((subtitle, index) => (
                  <div key={index} className="flex gap-6">
                    <p className={`text-[14px] leading-6 text-center ${index > 1 ? 'hidden lg:block' : ''}`}>
                      {subtitle}
                    </p>
                    {index !== subtitles.length - 1 && (
                      <div className={`-mt-[4px] ${index > 0 ? 'hidden lg:block' : ''}`}>.</div>
                    )}
                  </div>
                ))}
              </div>
            )}
            {description && (
              <div className="lg:col-[3]">
                <div
                  className={`text-[16px] lg:text-[14px] leading-6 lg:line-clamp-2 xxl:line-clamp-3  ${isHighlightedContentDesc} ${articleDescription}`}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            )}
            {isArticle && (
              <div className="h-[44px]">
                {primaryCta && (
                  <div className={`cta-containerpt-2 button-wrapper inline-flex justify-start`}>
                    <Button
                      theme={'dark'}
                      link={{ ...primaryCta, text: t('cards.$discoverMore') }}
                      type={'tertiary'}
                    ></Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};

GridCard.propTypes = {
  data: PropTypes.object.isRequired,
};

export default GridCard;
