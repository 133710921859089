/* eslint-disable no-unused-vars */
import React, { useRef, useEffect } from 'react';
import Flicking, { FlickingError, ViewportSlot } from '@egjs/react-flicking';
import '@egjs/flicking-plugins/dist/pagination.css';
import '@egjs/react-flicking/dist/flicking.css';
import { Pagination } from '@egjs/flicking-plugins';
import '@egjs/flicking-plugins/dist/pagination.css';
import classNames from 'classnames';
import { Button, ResponsiveImage } from '@/components';

const CardListItemsCarousel = ({ cards }) => {
  const carousel = useRef();

  useEffect(() => {
    carousel.current.on('ready', (e) => {
      e.currentTarget.addPlugins(
        new Pagination({
          type: 'bullet',
        }),
      );
    });
  }, []);

  return (
    <div>
      <Flicking
        ref={carousel}
        align="center"
        useResizeObserver={true}
        horizontal={true}
        circular={true}
        circularFallback="bound"
        interruptable={false}
        className="h-full CardCarouselFocusFullBleedModule"
      >
        {cards.map((card) =>
          card.props.cards.map((cardItem, cardIndex) => (
            <div key={cardIndex} className="card flex relative  w-full">
              <div className="h-screen relative w-full">
                <ResponsiveImage
                  image={cardItem.props.image}
                  widths={{ xs: 430, sm: 430, md: 511, lg: 511, xl: 511, xxl: 511 }}
                  heights={{ xs: 900, sm: 900, md: 900, lg: 900, xl: 900, xxl: 900 }}
                  imgProps={{ className: 'w-full h-full object-cover pointer-events-none' }}
                />
                <div className="absolute bg-black/40 left-0 top-0 w-full h-full"></div>
              </div>
              <div className="text-wrapper absolute left-0 bottom-[110px] px-4 md:max-w-[550px]">
                <p
                  className={classNames(
                    'font-ivy-ora font-normal not-italic',
                    'text-headingReg leading-headingReg -tracking-wide text-white',
                  )}
                  role="heading"
                  aria-level="2"
                >
                  {cardItem.props.headingTitle}
                </p>

                <div
                  className={classNames(
                    'mt-6 md:mt-3.5 font-cera text-white text-paragraph not-italic font-normal leading-6 duration-1000',
                  )}
                  dangerouslySetInnerHTML={{ __html: cardItem.props.description }}
                />

                <div>
                  <Button type="tertiary" theme={'light'} link={cardItem.props.primaryCta} className="w-fit mt-6" />
                </div>
              </div>
            </div>
          )),
        )}
        <ViewportSlot>
          <div
            className={`flicking-pagination flex items-center justify-center lg:hidden mb-[70px] 
                            [&_.flicking-pagination-bullet]:transition-all
                            [&_.flicking-pagination-bullet]:duration-200
                            [&_.flicking-pagination-bullet]:w-[5px] 
                            [&_.flicking-pagination-bullet]:h-[5px] 
                            [&_.flicking-pagination-bullet:last-child]:w-[3px] 
                            [&_.flicking-pagination-bullet:last-child]:h-[3px] 
                            [&_.flicking-pagination-bullet]:bg-white/40 
                            [&_.flicking-pagination-bullet-active]:!w-[5px]
                            [&_.flicking-pagination-bullet-active]:!h-[5px]
                            [&_.flicking-pagination-bullet-active]:!bg-white
                            `}
          ></div>
        </ViewportSlot>
      </Flicking>
    </div>
  );
};

export default CardListItemsCarousel;
